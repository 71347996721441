import { Controller } from "@hotwired/stimulus"
import { filteredNavigationType, selectContentEvent } from "@/helpers/ga4_helper"

export default class PriceFilterController extends Controller<HTMLDivElement> {
  declare readonly lowerInputTarget: HTMLInputElement
  declare readonly lowerLabelTarget: HTMLSpanElement
  declare readonly upperInputTarget: HTMLInputElement
  declare readonly upperLabelTarget: HTMLSpanElement
  declare readonly activeTrackTarget: HTMLDivElement
  declare readonly priceLinkTarget: HTMLAnchorElement
  declare stepValue: number
  declare maxValue: number

  static RANGE_SLIDER_THUMBNAIL_SIZE = 25 // See `$range-slider-thumb-size`
  static targets = [
    "lowerInput",
    "lowerLabel",
    "upperInput",
    "upperLabel",
    "activeTrack",
    "priceLink"
  ]

  static values = {
    max: Number,
    step: Number
  }

  connect(): void {
    this.initializeStyles()
  }

  applyFilter(e: Event): void {
    const url = this.isWholeRangeCovered ? this.element.dataset.urlForRemove! : this.urlForApply
    if (url === window.location.pathname) return

    e.preventDefault()
    this.priceLinkTarget.setAttribute("href", url)
    this.priceLinkTarget.click()

    selectContentEvent("Search Filters", this.selectedRangeAsString(), "Price Filter")
    filteredNavigationType()
  }

  lowerPriceChanged(e: Event): void {
    const input = e.target as HTMLInputElement
    const value = +input?.value

    e.preventDefault()
    this.updateLowerPrice(value)
  }

  lowerPriceLabelBlurred(e: KeyboardEvent): void {
    this.priceLabelBlurred(e, this.lowerInputTarget)
  }

  lowerPriceLabelChanged(e: KeyboardEvent): void {
    this.priceLabelChanged(e, this.lowerInputTarget)
  }

  upperPriceChanged(e: Event): void {
    const input = e.target as HTMLInputElement
    const value = +input?.value
    if (!value) return

    e.preventDefault()
    this.updateUpperPrice(value)
  }

  upperPriceLabelBlurred(e: KeyboardEvent): void {
    this.priceLabelBlurred(e, this.upperInputTarget)
  }

  upperPriceLabelChanged(e: KeyboardEvent): void {
    this.priceLabelChanged(e, this.upperInputTarget)
  }

  private initializeStyles(): void {
    const lowerValue = +this.lowerInputTarget.value
    const upperValue = +this.upperInputTarget.value
    this.setLowerLabel(lowerValue)
    this.setUpperLabel(upperValue)
    this.setActiveTrack(lowerValue, upperValue)
  }

  private isProhibitedKey(key: string): boolean {
    const allowedKeys = ["ArrowLeft", "ArrowRight", "Backspace"]
    const isNonDigit = /\D/.test(key)
    const isAllowedKey = allowedKeys.some((k) => k === key)

    return isNonDigit && !isAllowedKey
  }

  private get isWholeRangeCovered(): boolean {
    return +this.lowerInputTarget.value === 0 && +this.upperInputTarget.value === this.maxValue
  }

  private priceLabelBlurred(e: Event, input: HTMLInputElement): void {
    const editableElement = e.target as HTMLSpanElement
    const value = editableElement.textContent || input.value
    const cleanedValue = Math.min(this.maxValue, +value)
    const hasChanged = +input.value !== cleanedValue

    if (!hasChanged) {
      return
    }

    if (editableElement === this.lowerLabelTarget) {
      this.updateLowerPrice(cleanedValue)
    } else {
      this.updateUpperPrice(cleanedValue)
    }

    this.applyFilter(e)
  }

  private priceLabelChanged(e: KeyboardEvent, input: HTMLInputElement): void {
    const editableElement = e.target as HTMLSpanElement

    if (e.key === "Enter" || e.key === "Tab") {
      editableElement.blur()
    } else if (e.key === "Escape") {
      e.stopPropagation()
      editableElement.textContent = input.value
      editableElement.blur()
    } else if (this.isProhibitedKey(e.key)) {
      e.preventDefault()
    }
  }

  private selectedRangeAsString(): string {
    return `${this.lowerInputTarget.value}-${this.upperInputTarget.value}`
  }

  private setActiveTrack(lowerValue: number, upperValue: number): void {
    const left = (100 * lowerValue) / this.maxValue
    const width = (100 * (upperValue - lowerValue)) / this.maxValue

    this.activeTrackTarget.setAttribute(
      "style",
      `display:block;left:calc(${left}%);width:calc(${width}%);`
    )
  }

  private setLabel(value: number, label: HTMLSpanElement): void {
    const pct = value / this.maxValue
    let px, transformPct

    if (label === this.lowerLabelTarget) {
      px = PriceFilterController.RANGE_SLIDER_THUMBNAIL_SIZE * 0.5
      transformPct = pct >= 0.5 ? -1 : pct * -2
    } else {
      px = PriceFilterController.RANGE_SLIDER_THUMBNAIL_SIZE * 0.5 * (pct - 0.5)
      transformPct = pct >= 0.5 ? (pct - 0.5) * -2 : 0
    }

    label.setAttribute(
      "style",
      `display:inline-block;left:calc(${px}px + ${pct * 100}% - 1ch);transform:translateX(${transformPct * 100}%)`
    )
    label.textContent = value.toString()
  }

  private setLowerLabel(value: number): void {
    this.setLabel(value, this.lowerLabelTarget)
  }

  private setUpperLabel(value: number): void {
    this.setLabel(value, this.upperLabelTarget)
  }

  private updateLowerPrice(value: number): void {
    const newLowerValue = Math.min(value, this.maxValue - this.stepValue)
    const upperValue = +this.upperInputTarget.value

    this.lowerInputTarget.value = newLowerValue.toString()
    this.setLowerLabel(newLowerValue)

    if (newLowerValue >= upperValue) {
      const newUpperValue = Math.max(newLowerValue + this.stepValue, upperValue)
      this.updateUpperPrice(newUpperValue)
    } else {
      this.setActiveTrack(newLowerValue, upperValue)
    }
  }

  private updateUpperPrice(value: number): void {
    const newUpperValue = Math.max(value, this.stepValue)
    const lowerValue = +this.lowerInputTarget.value

    this.upperInputTarget.value = newUpperValue.toString()
    this.setUpperLabel(newUpperValue)

    if (newUpperValue <= lowerValue) {
      const newLowerValue = Math.min(newUpperValue - this.stepValue, lowerValue)
      this.updateLowerPrice(newLowerValue)
    } else {
      this.setActiveTrack(lowerValue, newUpperValue)
    }
  }

  private get urlForApply(): string {
    const { urlPrefix, urlSuffix } = this.element.dataset

    return `${urlPrefix}${this.selectedRangeAsString()}${urlSuffix}`
  }
}
