type Labels = {
  collapsed: string
  expanded: string
}

const CONTAINER_SELECTOR = ".js-collapsible-content-container"
const CONTENT_SELECTOR = ".js-collapsible-content"
const ACTION_SELECTOR = ".js-toggle-collapsible-content"
const COLLAPSE_CLASS = "is-collapsed"
const EXPANDED_TOGGLE_ICON = "<i class='far fa-chevron-up fa:after' aria-hidden='true'></i>"
const COLLAPSED_TOGGLE_ICON = "<i class='far fa-chevron-down fa:after' aria-hidden='true'></i>"

const toggleClass =
  (content: HTMLElement, labels: Labels) =>
  (e: Event): void => {
    if (!content || !e.target) return

    e.preventDefault()
    const toggle = (e.target as HTMLElement).closest("button")

    if (toggle) {
      toggle.innerHTML = content.classList.contains(COLLAPSE_CLASS)
        ? labels.expanded
        : labels.collapsed
    }

    content.classList.toggle(COLLAPSE_CLASS)
  }

export const isTextOverflowed = (container: HTMLElement): boolean => {
  const overflow = container.style.overflow

  if (!overflow || overflow === "visible") {
    container.style.overflow = "hidden"
  }

  const isOverflowing =
    container.clientHeight < container.scrollHeight || container.clientWidth < container.scrollWidth

  container.style.overflow = overflow
  return isOverflowing
}

const isDisplayNone = (el: HTMLElement): boolean => !el || !el.offsetParent

export default (parent: HTMLElement | Document): void => {
  for (const container of parent.querySelectorAll(CONTAINER_SELECTOR)) {
    const content = container.querySelector(CONTENT_SELECTOR) as HTMLElement
    const toggle = container.querySelector(ACTION_SELECTOR) as HTMLElement

    if (!content || !toggle || content.dataset.overflowed || isDisplayNone(content)) {
      continue
    }

    if (isTextOverflowed(content)) {
      const labels = {
        collapsed: `${toggle.dataset.toggleCollapsed} ${COLLAPSED_TOGGLE_ICON}`,
        expanded: `${toggle.dataset.toggleExpanded} ${EXPANDED_TOGGLE_ICON}`
      }

      toggle.innerHTML = labels.collapsed
      toggle.addEventListener("click", toggleClass(content, labels))
      content.dataset.overflowed = "true"
    } else {
      toggle.classList.add("!hidden")
      content.classList.remove(COLLAPSE_CLASS)
    }
  }
}
