import { Controller } from "@hotwired/stimulus"

export interface ImageSlide {
  imgAlt: string
  imgHeight: string
  imgSrc: string
  imgWidth: string
  srcset1x: string
  srcset2x: string
  srcsizes: string
}

export default class MapMarkerCarouselController extends Controller {
  static values = { id: String, images: Array }
  declare readonly idValue: string
  declare readonly imagesValue: ImageSlide[]

  connect(): void {
    this.element.outerHTML = this.carouselView
  }

  private get carouselId(): string {
    return `carousel-search-map-${this.idValue}`
  }

  private get carouselName(): string {
    return "Search Map"
  }

  private get carouselView(): string {
    return `
      <div
        class="ui-component-carousel"
        data-controller="carousel"
        data-carousel-name-value="${this.carouselName}"
      >
        <div>
          <ol
            id="${this.carouselId}"
            aria-live="polite"
            tabindex="0"
            data-carousel-target="slideList"
          >
            ${this.imagesValue.map(this.slideListItem.bind(this)).join("")}
          </ol>

          <button
            type="button"
            aria-label="Previous slide"
            aria-controls="${this.carouselId}"
            data-action="click->carousel#slidePrev"
            data-carousel-target="prevButton"
            data-ga4-click="carousel_click"
            data-ga4-event-group="${this.carouselName}"
            data-ga4-click-target="Previous"
            aria-disabled="true"
          >
            <i class="far fa-chevron-left fa-2x" aria-hidden="true"></i>
          </button>
          <button
            type="button"
            aria-label="Next slide"
            aria-controls="${this.carouselId}"
            data-action="click->carousel#slideNext"
            data-carousel-target="nextButton"
            data-ga4-click="carousel_click"
            data-ga4-event-group="${this.carouselName}"
            data-ga4-click-target="Next"
          >
            <i class="far fa-chevron-right fa-2x" aria-hidden="true"></i>
          </button>
        </div>

        <ol class="carousel-indicators">
          ${this.imagesValue.map(this.indicatorListItem.bind(this)).join("")}
        </ol>
      </div>
    `
  }

  private indicatorListItem(_: ImageSlide, index: number): string {
    const iconClass = `fas fa-xs fa-circle ${index === 0 ? "fa-dot-circle" : ""}`

    return `
      <li>
        <button
          type="button"
          aria-label="Go to slide ${index + 1}"
          aria-controls="${this.carouselId}"
          data-action="click->carousel#slideTo"
          data-carousel-index-param="${index}"
          data-ga4-click="carousel_click"
          data-ga4-event-group="${this.carouselName}"
          data-ga4-click-target="Indicator"
        >
          <i class="${iconClass}" data-carousel-target="indicatorIcon" aria-hidden="true"></i>
        </button>
      </li>
    `
  }

  private pictureTag({
    imgAlt,
    imgHeight,
    imgSrc,
    imgWidth,
    srcset1x,
    srcset2x,
    srcsizes
  }: ImageSlide): string {
    return `
      <picture>
        <source
          media="(min-resolution: 1.5x)"
          srcset="${srcset2x}"
          sizes="${srcsizes}"
        >
        <source srcset="${srcset1x}">
        <img
          src="${imgSrc}"
          width="${imgWidth}"
          height="${imgHeight}"
          alt="${imgAlt}"
          fetchpriority="auto"
          loading="lazy"
        >
      </picture>
    `
  }

  private slideListItem(image: ImageSlide, index: number): string {
    const position = index + 1

    return `
      <li
        aria-roledescription="slide"
        aria-posinset="${position}"
        aria-setsize="${this.imagesValue.length}"
        aria-label="Slide ${position}"
        aria-hidden="${position > 1 ? "true" : "false"}"
        data-carousel-target="slideListItem"
        ${position > 2 ? "hidden" : ""}
      >
        ${this.pictureTag(image)}
      </li>
    `
  }
}
