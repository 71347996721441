import { type ActionEvent, Controller } from "@hotwired/stimulus"

export default class CarouselController extends Controller {
  static targets = ["indicatorIcon", "nextButton", "prevButton", "slideList", "slideListItem"]
  declare readonly indicatorIconTargets: HTMLElement[]
  declare readonly nextButtonTarget: HTMLButtonElement
  declare readonly prevButtonTarget: HTMLButtonElement
  declare readonly slideListTarget: HTMLOListElement
  declare readonly slideListItemTargets: HTMLLIElement[]

  static values = { name: String, selectedIndex: Number }
  declare readonly nameValue: string
  declare selectedIndexValue: number

  declare private firstSlideObserver: IntersectionObserver
  declare private lastSlideObserver: IntersectionObserver

  initialize(): void {
    this.firstSlideObserver = this.slideObserver(this.prevButtonTarget)
    this.lastSlideObserver = this.slideObserver(this.nextButtonTarget)
  }

  connect(): void {
    this.firstSlideObserver.observe(this.slideListItemTargets.at(0)!)
    this.lastSlideObserver.observe(this.slideListItemTargets.at(-1)!)

    // If carousel already interacted with (e.g. arriving via back/forward button), scroll to selected
    if (this.hasInteracted) {
      this.scrollToSelected()
    }
  }

  disconnect(): void {
    this.firstSlideObserver.disconnect()
    this.lastSlideObserver.disconnect()
  }

  slideNext(): void {
    // Normally a `disabled` button would preclude this function from running, but since we can't
    // use that attribute due to keyboard scrolling, we need to manually check
    if (this.isNextButtonEnabled) {
      this.setSelectedIndex(this.selectedIndexValue + 1)
    }
  }

  slidePrev(): void {
    // Normally a `disabled` button would preclude this function from running, but since we can't
    // use that attribute due to keyboard scrolling, we need to manually check
    if (this.isPrevButtonEnabled) {
      this.setSelectedIndex(this.selectedIndexValue - 1)
    }
  }

  slideTo({ params: { index } }: ActionEvent): void {
    this.setSelectedIndex(index)
  }

  toggleFocus({ currentTarget }: IEvent<HTMLButtonElement>): void {
    // This is for keyboard scrolling -- element needs to be focused to receive keydown events
    if (document.activeElement === currentTarget) {
      currentTarget.blur()
    } else {
      currentTarget.focus()
    }
  }

  private clampIndex(index: number): number {
    return Math.max(0, Math.min(index, this.slideListItemTargets.length - 1))
  }

  private get hasInteracted(): boolean {
    return this.slideListItemTargets.some(
      (slide, index) => index > 0 && slide.getAttribute("aria-hidden") === "false"
    )
  }

  private get isAllSlidesLoaded(): boolean {
    return this.slideListItemTargets.every((slide) => !slide.hidden)
  }

  private get isNextButtonEnabled(): boolean {
    return !this.nextButtonTarget.hasAttribute("aria-disabled")
  }

  private get isPrevButtonEnabled(): boolean {
    return !this.prevButtonTarget.hasAttribute("aria-disabled")
  }

  private scrollToSelected(): void {
    this.slideListItemTargets[this.selectedIndexValue]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start"
    })
  }

  private setSelectedIndex(index: number): void {
    if (!this.isAllSlidesLoaded) {
      this.slideListItemTargets.forEach((slide) => (slide.hidden = false))
    }

    this.selectedIndexValue = this.clampIndex(index)
    this.scrollToSelected()
    this.toggleAttributesAndIndicators()
  }

  private slideObserver(navButton: HTMLButtonElement): IntersectionObserver {
    return new IntersectionObserver(
      (entries) => {
        entries.forEach(({ isIntersecting }) => {
          // To avoid accessibility issues, we need to use aria-disabled attribute instead of aria-hidden
          // because according to WAI-ARIA, aria-hidden="true" should not hide focusable content from
          // assistive technology users.
          if (isIntersecting) {
            navButton.setAttribute("aria-disabled", "true")
          } else {
            navButton.removeAttribute("aria-disabled")
          }
        })
      },
      { root: this.slideListTarget, threshold: 0.9 }
    )
  }

  private toggleAttributesAndIndicators(): void {
    this.slideListItemTargets.forEach((slide, index) => {
      const isSelected = index === this.selectedIndexValue

      slide.setAttribute("aria-hidden", isSelected ? "false" : "true")
      this.indicatorIconTargets[index]?.classList.toggle("fa-dot-circle", isSelected)
    })
  }
}
